import {
  ActionIcon,
  Anchor,
  Card,
  Flex,
  Group,
  Popover,
  Indicator,
  RangeSlider,
  Slider,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import {
  Dispatch,
  FC,
  SetStateAction,
  forwardRef,
  useMemo,
  useState,
  useEffect,
} from 'react';

import {
  IconFilter,
  IconGripVertical,
  IconSearch,
  IconX,
} from '@tabler/icons-react';
import QueryBuilder from '@components/QueryBuilder';
import { useAnalysisEditionStore } from '@stores/AnalysisEditionStore';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { addMissingColumns, formatAvailableFields } from '@utils/MetadataUtils';
import { useCurrentProject } from '@hooks/useCurrentProject';
import {Loader} from '@mantine/core';
import { arrayFor } from './testArr';


interface ExplorationFiltersProps {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  numberOfResponses: [number, number];
  setNumberOfResponses: Dispatch<SetStateAction<[number, number]>>;
  maxNumberOfResponses: number;
}

const ExplorationFilters: FC<ExplorationFiltersProps> = ({
  searchQuery,
  setSearchQuery,
  numberOfResponses,
  setNumberOfResponses,
  maxNumberOfResponses,
}) => {
  const globalFilter = useAnalysisEditionStore((s) => s.globalFilter);
  const setGlobalFilter = useAnalysisEditionStore((s) => s.setGlobalFilter);
  const dataset = useAnalysisEditionStore((s) => s.dataset);
  const project = useCurrentProject();
  const { data: datasetsMetadata } = useProjectDatasets(project);
  const [availableFieldsMetaData, setAvailableFieldsMetaData] = useState<Map<string, any> | null>(null);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(false)
  useEffect(() => {
    if (datasetsMetadata && project?.projectId && dataset) {
      addMissingDataInColumns()
    }
  }, [datasetsMetadata, project?.projectId, dataset])

  async function addMissingDataInColumns() {
    try {
      setIsLoadingFilters(true)
      const newData = await addMissingColumns(datasetsMetadata, project.projectId, dataset)
      setAvailableFieldsMetaData(newData)
      setIsLoadingFilters(false)
    } catch (error) {
      setIsLoadingFilters(false)
    }
  }

  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );
  let availableFields = useMemo(
    () => (availableFieldsMetaData ? formatAvailableFields(availableFieldsMetaData, project.projectId, dataset) : {}),
    [availableFieldsMetaData]
  );


  // console.log(datasetsMetadata)
  // console.log("THETYPE",availableFields["@Glassdoor_US_C1000_Narrow_2019_2023@employerName"])
  // console.log({
  //   focalPopulation: { GLOBAL_FILTER: 'GLOBAL_FILTER' },
  //   initialDefinition: { globalFilter },

  //   metadata: { datasetsMetadata },
  //   datasets: { datasetsMetadata },
  //   availableFields: { availableFields },
  // })

  // console.log({
  //   globalFilter,
  //   datasetsMetadata

  // })

  // console.log(JSON.stringify(availableFields))

  return (
    <Flex align="center" justify="end" gap="xs">
      <TextInput
        style={{ width: 200 }}
        size="xs"
        placeholder="Search values..."
        rightSection={SearchIcon}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <Popover width={650} position="bottom-end" shadow="md">
        <Popover.Target>
          <Indicator
            disabled={
              globalFilter === undefined &&
              numberOfResponses[0] === 0 &&
              numberOfResponses[1] === maxNumberOfResponses
            }
          >
            {
              isLoadingFilters ? <Loader size={"sm"} /> : (
                <ThemeIcon
                  size={30}
                  variant="light"
                  color="gray"
                  aria-label="Filters"
                  style={{ cursor: 'pointer' }}
                >
                  <IconFilter
                    style={{ width: '70%', height: '70%' }}
                    stroke={1.5}
                  />
                </ThemeIcon>
              )
            }

          </Indicator>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack spacing="xs">
            <Text size="sm">{`# Responses (${numberOfResponses[0].toLocaleString()} - ${numberOfResponses[1].toLocaleString()})`}</Text>
            <RangeSlider
              defaultValue={[0, maxNumberOfResponses]}
              miw={150}
              min={0}
              size="sm"
              thumbSize={13}
              max={maxNumberOfResponses}
              value={numberOfResponses}
              label={(value) => value.toLocaleString()}
              onChange={setNumberOfResponses}
              styles={{ markLabel: { display: 'none' } }}
            />
            <Text size="sm" mt="sm">
              Global filter
            </Text>
            {datasetsMetadata && (
              <QueryBuilder
                focalPopulation={'GLOBAL_FILTER'}
                initialDefinition={globalFilter}
                onDefinitionUpdate={(
                  populationName: string,
                  definition: object
                ) => {
                  setGlobalFilter(definition);
                }}
                metadata={datasetsMetadata}
                datasets={datasetsMetadata}
                availableFields={{
                  ...availableFields,
                }}
                // availableFields={{
                //   "@Big20_15_NOV_24@employerName": {
                //     "label": null,
                //     "dataType": "StringType",
                //     "numberOfUniqueValues": 11199,
                //     "type": "select",
                //     "operators": ["select_equals"],
                //     "valueSources": ["value"]
                //   },
                //   "Big20_15_NOV_24@employerName": {
                //     "label": "Big20_15_NOV_24",
                //     "type": "select",
                //     "operators": [
                //       "select_equals",
                //       "select_not_equals",
                //       "select_any_in",
                //       "select_not_any_in"
                //     ],
                //     "valueSources": ["value"],
                //     "fieldSettings": {
                //       "listValues": arrayFor.map((value,index)=>{return ({...value, title:value.title + " " + index})}),
                //       "min": 0,
                //       "max": 0
                //     }
                //   }
                // }}
              // availableFields={{
              //   "Glassdoor_US_C1000_Narrow_2019_2023@employerTypeCode":
              //     availableFields["Glassdoor_US_C1000_Narrow_2019_2023@employerTypeCode"],
              //   "Glassdoor_US_C1000_Narrow_2019_2023@BusinessOutlook":
              //     availableFields["Glassdoor_US_C1000_Narrow_2019_2023@BusinessOutlook"],
              //     "Glassdoor_US_C1000_Narrow_2019_2023@CEO":
              //     availableFields["Glassdoor_US_C1000_Narrow_2019_2023@CEO"],
              //     // "Glassdoor_US_C1000_Narrow_2019_2023@employerName":
              //     // availableFields["Glassdoor_US_C1000_Narrow_2019_2023@employerName"],
              // }}
              />
            )}
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </Flex>
  );
};

export default ExplorationFilters;
